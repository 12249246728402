import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Page
import LOBreadcrumb from "./LOBreadcrumb";

// Authentication
import SignInWithGoogle from '../../Components/Authentication/SignInWithGoogle';
import SignInWithMicrosoft from '../../Components/Authentication/SignInWithMicrosoft';

// Helpers
import OpenAI from "openai";
import Confetti from 'react-confetti'
import posthog from 'posthog-js'


function LOCoordinator({lessonId, uid}){

     // EXPERIMENT STATE
    const [audience, setAudience] = useState('');
    const [behavior, setBehavior] = useState('');
    const [condition, setCondition] = useState('');
    const [degree, setDegree] = useState('');
    const [academicStandard, setAcademicStandard] = useState('');
    const [classroomDescription, setClassroomDescription] = useState('');
    const [learningObjective, setLearningObjective] = useState('');

    // EXPERIMENT CALLBACKS
    function audienceCallback(audience){setAudience(audience)}
    function behaviorCallback(behavior){setBehavior(behavior)}
    function conditionCallback(condition){setCondition(condition)}
    function degreeCallback(degree){setDegree(degree)}
    function academicStandardCallback(academicStandard){setAcademicStandard(academicStandard)}
    function classroomDescriptionCallback(classroomDescription){
        setClassroomDescription(classroomDescription)
    }
    function learningObjectiveCallback(learningObjective){setLearningObjective(learningObjective)}

    const [step, setStep] = useState(1);
    const navigate = useNavigate('');
    function navigateTo(destination){
        navigate(destination);
    }
    function updateStep(direction){
        if (direction === 'forward'){
            if (step === 8){
                navigateTo(`/l/${lessonId}`)
            } else {
                setStep(step + 1);
            }
        } else if (direction === 'back'){
            if (step === 1){
                navigateTo(`/l/${lessonId}`)
            } else {
                setStep(step - 1);
            }
        } else {
            return;
        }
    }

    let content;
    if (step === 1) {
        content = <LOStepOne 
            stepChangeCallback={updateStep} 
            lessonId={lessonId} 
            userId={uid} 
        />;
    } else if (step === 2){
        content = <LOStepOnePointFive 
            stepChangeCallback={updateStep} 
            lessonId={lessonId} 
            userId={uid}
            academicStandardCallback={academicStandardCallback}
            classroomDescriptionCallback={classroomDescriptionCallback}
        />;
    } else if (step === 3) {
        content = <LOStepTwo 
            stepChangeCallback={updateStep} 
            lessonId={lessonId} 
            userId={uid}
            academicStandard={academicStandard}
            classDescription={classroomDescription}
            audienceCallback={audienceCallback}
        />;
    } else if (step === 4) {
        content = <LOStepThree 
            stepChangeCallback={updateStep} 
            lessonId={lessonId} 
            userId={uid}
            academicStandard={academicStandard}
            classDescription={classroomDescription}
            audience={audience}
            behaviorCallback={behaviorCallback}
        />;
    } else if (step === 5){
        content = <LOStepFour 
            stepChangeCallback={updateStep} 
            lessonId={lessonId} 
            userId={uid}
            academicStandard={academicStandard}
            classDescription={classroomDescription}
            audience={audience}
            behavior={behavior}
            conditionCallback={conditionCallback}
        />;
    } else if (step === 6){
        content = <LOStepFive 
            stepChangeCallback={updateStep} 
            lessonId={lessonId}
            userId={uid}
            academicStandard={academicStandard}
            classDescription={classroomDescription}
            audience={audience}
            behavior={behavior}
            condition={condition}
            degreeCallback={degreeCallback}
        />;
    } else if (step === 7){
        content = <LOStepSix 
            stepChangeCallback={updateStep} 
            lessonId={lessonId} 
            userId={uid}
            academicStandard={academicStandard}
            classDescription={classroomDescription}
            audience={audience}
            behavior={behavior}
            condition={condition}
            degree={degree}
            learningObjectiveCallback={learningObjectiveCallback}
        />;
    } else if (step === 8){
        content = <LOStepSeven 
            stepChangeCallback={updateStep} 
            lessonId={lessonId} 
            userId={uid}
            audience={audience}
            behavior={behavior}
            condition={condition}
            degree={degree}
            finalLearningObjective={learningObjective}
        />;
    }
    else {
        content = <LOStepOne stepChangeCallback={updateStep} lessonId={lessonId} userId={uid}/>;
    }
    
    return(
        <div className="bg-[#f0f0f0] min-h-screen max-h-full">
            {content}
            <div className="fixed bottom-0 left-0 w-full z-50 bg-white">
                <LOBreadcrumb step={step} />
            </div>
        </div>
    )
}

export default LOCoordinator;


export function LOStepOne({stepChangeCallback}){
    
    function moveFromStep(direction){

        if (direction === "back"){
            stepChangeCallback(direction);
        } else if (direction === "forward") {
            stepChangeCallback(direction);
        } else {
            return;
        }
    }

    return(
        <div className=''>
            <h5 className="font-semibold text-2xl">Writing a Learning Objective</h5>
            <p className="font-light mt-1">Because ClassroomGPT uses the backward design approach to lesson planning, the foundational element that guides the lesson design process is the learning objective. You will use the ABCD method to iteratively develop your learning objective. There are four steps in this process. As you work through each step (Audience, Behavior, Condition, and Degree), you will be coached through each step with the final result being a clearly written learning objective that you will use to further develop your lesson plan. </p>
            <div class="flex mt-6">
                <a onClick={() => moveFromStep('back')} class="hover:cursor-pointer duration-200 ease-in-out transition flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="hover:cursor-pointer duration-200 ease-in-out transition flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )
}

export function LOStepOnePointFive({stepChangeCallback, academicStandardCallback, classroomDescriptionCallback}){

    const [academicStandard, setAcademicStandard] = useState('');
    const [classDescription, setClassDescription] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    function onAcademicStandardChange(event){setAcademicStandard(event.target.value)}
    function onClassDescriptionChange(event){setClassDescription(event.target.value)}

    function moveFromStep(direction){

        setErrorMessage('');

        if (direction === "back"){
            // In coordinator, should return to lesson plan page
            stepChangeCallback(direction);
        } else if (direction === "forward") {

            // Check if inputs are filled out
            if (classDescription === ''){
                setErrorMessage('Please fill out class description before continuing.')
                return;
            } else {
                // Save information to Firebase
                academicStandardCallback(academicStandard);
                classroomDescriptionCallback(classDescription);

                posthog.capture('Set Academic Standard', { property: academicStandard })
                posthog.capture('Set Classroom Description', { property: classDescription })
                stepChangeCallback(direction);
            }

        } else {
            return;
        }
    }

    return(
        <div className="">
            <h5 className="font-semibold text-2xl">Learning Objective Preparation</h5>
            <p className="font-light leading-5 mt-1">In preparing to create a learning objective, we will <b>analyze your students' needs</b> and <b>identify the academic standard</b> you will be designing your lesson plan around.</p>
            
            <div class="mb-6 mt-6">
                <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900">Class description</label>
                <input value={classDescription} onInputCapture={onClassDescriptionChange} placeholder="8th grade California Biology class teaching to NGSS standards" type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 font-light text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                {/* <p id="helper-text-explanation" class="mt-2 text-sm text-gray-800 font-normal">Important information: grade level, state, course title, etc.</p> */}
            </div>

            <div class="mb-6">
                <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900">Academic standard</label>
                <input value={academicStandard} type="text" id="default-input" placeholder="MS-LS4-6.	Use mathematical representations to support explanations of how natural selection..." onInputCapture={onAcademicStandardChange} class="bg-gray-50 border border-gray-300 text-gray-900 font-light text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                <p id="helper-text-explanation" class="mt-2 text-sm text-gray-800 font-normal">If you do not have an academic standard, you can skip.</p>
            </div>

            <div className="mb-6">
                <p className="text-red-950 text-sm font-semibold">{errorMessage}</p>
            </div>

            <div class="flex mt-6">
                <a onClick={() => moveFromStep('back')} class="duration-200 ease-in-out transition hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="duration-200 ease-in-out transition hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )
}

export function LOStepTwo({stepChangeCallback, audienceCallback, academicStandard, classDescription}){
    
    const [isLoading, setIsLoading] = useState(false);
    const [context, setContext] = useState([]);

    const [currentResponse, setCurrentResponse] = useState('');
    const [userFeedback, setUserFeedback] = useState('');
    const [instruction, setInstruction] = useState('');
    const [lastBest, setLastBest] = useState('');


    function onUserFeedbackUpdate(event){
        setUserFeedback(event.target.value);
    }

    function moveFromStep(direction){

        if (direction === "back"){
            stepChangeCallback(direction);
        } else if (direction === "forward") {
            // No audience generated
            if (lastBest === ''){
                setInstruction('Please write an Audience before continuing.')
                return;
            } else {
                audienceCallback(lastBest);
                posthog.capture('Set Audience', { property: lastBest })
                stepChangeCallback(direction);
            }
        } else {
            return;
        }
    }

    // Responding to all user inputs
    async function submitUserResponse(){

        setIsLoading(true);

        // No user input
        if (userFeedback.length < 5){
            setInstruction('To submit feedback, please enter it in the above input. If you are satisfied with my response, you can proceed.');
            setIsLoading(false);
            return;
        }

        if (context.length === 0) { // FIRST input

            // #1: Create initial prompt
            let frameworkIntroduction = "A learning objective is what you want your students to know or be able to do once they have participated in instruction. A learning objective is the foundation of a well-written lesson plan. One of the most direct and effective ways to write a learning objective is to use the ABCD approach."
            let audienceGuidelines = "Audience, which is the A in ABCD, defines the intended audience for the learning objective. When writing Audience, an educator should consider the following: Who is the target audience? In other words, who will be expected to meet the learning objective? Your classroom students are generally the audience. So, for the purposes of an ABCD objective, you would indicate that your audience would be my learners, students, or learners. You could be more specific; for example, 8th-grade students, students in honors calculus, or students who need additional instruction. Typically, however, students or learners are how the audience is written."
            let commonAudienceFailures = "When writing the Audience component of a learning objective, common traps to avoid include: defining an audience that is different from your class or intended audience and being too general about your audience."
            frameworkIntroduction = `${frameworkIntroduction}\n\n ${audienceGuidelines}\n\n${commonAudienceFailures}\n\n`

            let conversationContext = `The following is a conversation with a ${classDescription} educator creating a learning objective for a lesson with the following academic standard: ${academicStandard}. The educator will submit their version of Audience and you, an instructional coach, will either 1. approve it and explain what they did well or 2. disapprove of it and explain what needs to be changed. Be constructive but succinct.\n\nEducator: For Audience, I will use: ${userFeedback}.\n\nInstructional Coach:`;
            let fullPrompt = `${frameworkIntroduction}\n\n${audienceGuidelines}\n\n${conversationContext}`;
            let firstMessage = {role: 'system', content: fullPrompt};

            // #2: Generate first response
            const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
            const completion = await openai.chat.completions.create({
                model: "gpt-4-0613",
                messages: [firstMessage],
                temperature: 0.5,
                max_tokens: 500
            });

             // Step #3: Add response to context
            let secondMessage = completion.choices[0].message
            const newContext = [...context, firstMessage, secondMessage];
            setContext(newContext);

            // Step #3.5: Analytics
            posthog.capture('Audience Input', { property: userFeedback })
            posthog.capture('Audience Feedback', { property: secondMessage })

            // Step #4: Render response to user
            let newResponse = completion.choices[0].message.content;
            setCurrentResponse(newResponse);
            setIsLoading(false);

            // Step #5: Render guidelines message
            setInstruction('Agree? Disagree? Once you are satisfied, you can move to the next section.')

            // Step #6: Store copy of last response
            setLastBest(userFeedback);
            setUserFeedback('');

            setIsLoading(false);


        } else {    // Follow up inputs

            // Step #1: Housekeeping before rendering new response
            let feedbackForPrompt = userFeedback;
            setUserFeedback('');
            setIsLoading(true);

            // Step #2: Build next prompt
            let nextPrompt = feedbackForPrompt; // customize later on
            let nextMessage = {role: 'user', content: nextPrompt}

            // Step #3: Generate next response
            const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
            const completion = await openai.chat.completions.create({
                 model: "gpt-4-0613",
                messages: [...context, nextMessage],
                temperature: 0.5,
                max_tokens: 500
            });

            // Step #4: Add response to context
            let nextNextMessage = completion.choices[0].message
            const newContext = [...context, nextMessage, nextNextMessage];
            setContext(newContext);

            // Step #3.5: Analytics
            posthog.capture('Audience Input', { property: userFeedback })
            posthog.capture('Audience Feedback', { property: nextNextMessage })

            // Step #5: Render response to user
            let newResponse = completion.choices[0].message.content;
            setCurrentResponse(newResponse);
            setIsLoading(false);

            // Step #5: Render guidelines message
            setInstruction('Agree? Disagree? How should I adjust this? Once you are satisfied, you can move to the next section.')

            // Step #6: Store copy of last response
            setLastBest(feedbackForPrompt);
        }
    }

    return(
        <div className=''>
            <h5 className="font-semibold text-2xl">Audience</h5>
            <p className="font-light">Audience describes the intended audience for your lesson. Consider: Who will be expected to meet the learning objective? An example of the Audience in a learning objective is: 'My ENGL397 students will be able to...'</p>
            {
                isLoading === true ? (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full h-48 bg-gray-50 rounded-md my-6 border border-gray-300">
                            <div class="flex items-center justify-center w-full h-full">
                                <div role="status">
                                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-[#238673]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        
                        {/* User responses */}
                        <div class="mb-6">
                            <input type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#238673] focus:border-[#238673] block w-full p-2.5" />
                        </div>
                    </div>
                ) : (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full max-h-full bg-gray-50 rounded-md my-6 border border-gray-300">
                            <p className="py-4 px-6">{currentResponse}</p>
                        </div>
                        
                        {/* User response */}
                        <div class="mb-2 flex flex-row justify-between">
                            <input value={userFeedback} onInputCapture={onUserFeedbackUpdate} type="text" id="default-input" class="h-max bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                            <button onClick={submitUserResponse} type="button" class="transition duration-200 ease-in-out ml-1 text-white bg-[#238673] hover:bg-[#196455] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">Submit</button>
                        </div>
                        <p className="text-red-950 text-sm font-semibold">{instruction}</p>
                    </div>
                )
            }
            <div class="flex mt-6">
                <a onClick={() => moveFromStep('back')} class="duration-200 ease-in-out transition hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="duration-200 ease-in-out transition hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )
}

export function LOStepThree({stepChangeCallback, behaviorCallback, academicStandard, classDescription, audience}){
    
    const [isLoading, setIsLoading] = useState(false);
    const [context, setContext] = useState([]);

    const [currentResponse, setCurrentResponse] = useState('');
    const [userFeedback, setUserFeedback] = useState('');
    const [instruction, setInstruction] = useState('');
    const [lastBest, setLastBest] = useState('');


    function onUserFeedbackUpdate(event){
        setUserFeedback(event.target.value);
    }

    function moveFromStep(direction){

        if (direction === "back"){
            stepChangeCallback(direction);
        } else if (direction === "forward") {

            // No behavior generated
            if (lastBest === ''){
                setInstruction('Please write a Behavior before continuing.')
                return;
            } else {
                behaviorCallback(lastBest);
                posthog.capture('Set Behavior', { property: lastBest })
                stepChangeCallback(direction);
            }

        } else {
            return;
        }
    }

    // Responding to all user inputs
    async function submitUserResponse(){

        setIsLoading(true);

        // No user input
        if (userFeedback.length < 4){
            setInstruction('To submit feedback, please enter it in the above input. If you are satisfied with my response, you can proceed.');
            setIsLoading(false);
            return;
        }

        if (context.length === 0) { // FIRST input

            // #1: Create initial prompt
            let frameworkIntroduction = "A learning objective is what you want your students to know or be able to do once they have participated in instruction. A learning objective is the foundation of a well-written lesson plan. One of the most direct and effective ways to write a learning objective is to use the ABCD approach. This approach makes sure that you focus on one verb (known as a behavior) that you want your students to be able to accomplish."
            let behaviorGuidelines = "Behavior, which is the B in ABCD, refers to the observable and measurable actions or skills that learners will demonstrate as a result of the instructional material. When writing Behavior, an educator should consider the following: What is the outcome of the learners participating in the instruction?  In other words, what is the audience expected to know or be able to do based on engaging in the instruction? This needs to be written as a verb (this is the “behavior”). What you expect your learners to know or be able to do (the verb) must be observable. It must also be measurable. There are three major domains that a behavior could fall into. These are the cognitive domain, psychomotor domain, or the affective domain.  The behavior will only be in one of the three domains. The cognitive domain focuses on intellectual activity such as thinking, remembering, reasoning, and evaluating.  Historically, Bloom’s Taxonomy has been used to describe the behaviors that fit as part of the cognitive domain. Verbs that fit within this domain range from lower-level thinking processes (e.g., remembering) to higher-level thinking processes (e.g., synthesizing, evaluating, judging, creating). The psychomotor domain focuses on physical functions, actions, and movements. Verbs that fit within this domain range from the level of imitation (e.g., copy or replicate an action) to the levels of articulation (e.g., adapt, integrate, modify) and naturalization (e.g., automatic actions, intuitive responses). The affective domain focuses on emotions and feelings. This domain is the most difficult to create learning objectives that can be measured. Verbs that fit with this domain range from the receiving level (e.g., feel, sense, experience) to the characterization level (e.g., conclude, internalize, resolve).  You need to include only one verb. The verb must be something that can be measured.";
            let commonBehaviorFailures = "When writing the Behavior component of a learning objective, common traps to avoid include: using two verbs to describe the behavior. For example: 'Students will be able to design and implement' uses two verbs instead of focusing on one. If you need to use two verbs, break it into two separated learning objectives."
            frameworkIntroduction = `${frameworkIntroduction}\n\n ${behaviorGuidelines}\n\n${commonBehaviorFailures}\n\n`

            let conversationContext = `The following is a conversation with a ${classDescription} educator creating a learning objective ${academicStandard}. The educator will submit their version of Behavior and you, an instructional coach, will either 1. approve it with feedback or 2. disapprove of it with feedback. Be constructive but succinct.\n\nEducator: My Audience is: ${audience}. For Behavior, I want to use: "${userFeedback}".\n\nInstructional Coach:`;
            let fullPrompt = `${frameworkIntroduction}\n\n${behaviorGuidelines}\n\n${conversationContext}`
            let firstMessage = {role: 'system', content: fullPrompt};

            // #2: Generate first response
            const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
            const completion = await openai.chat.completions.create({
                model: "gpt-4-0613",
                messages: [firstMessage],
                temperature: 0.5,
                max_tokens: 500
            });

             // Step #3: Add response to context
            let secondMessage = completion.choices[0].message
            const newContext = [...context, firstMessage, secondMessage];
            setContext(newContext);

            // Step #3.5: Analytics
            posthog.capture('Behavior Input', { property: userFeedback })
            posthog.capture('Behavior Feedback', { property: secondMessage })

            // Step #4: Render response to user
            let newResponse = completion.choices[0].message.content;
            setCurrentResponse(newResponse);
            setIsLoading(false);

            // Step #5: Render guidelines message
            setInstruction('Once you are satisfied with my response, you can move to the next section.')

            // Step #6: Store copy of last response
            setLastBest(userFeedback);
            setUserFeedback('');

            setIsLoading(false);


        } else {    // Follow up inputs

            // Step #1: Housekeeping before rendering new response
            let feedbackForPrompt = userFeedback;
            setUserFeedback('');
            setIsLoading(true);

            // Step #2: Build next prompt
            let nextPrompt = feedbackForPrompt; // customize later on
            let nextMessage = {role: 'user', content: nextPrompt}

            // Step #3: Generate next response
            const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
            const completion = await openai.chat.completions.create({
                 model: "gpt-4-0613",
                messages: [...context, nextMessage],
                temperature: 0.5,
                max_tokens: 500
            });

            // Step #4: Add response to context
            let nextNextMessage = completion.choices[0].message
            const newContext = [...context, nextMessage, nextNextMessage];
            setContext(newContext);

            // Step #3.5: Analytics
            posthog.capture('Behavior Input', { property: userFeedback })
            posthog.capture('Behavior Feedback', { property: nextNextMessage })

            // Step #5: Render response to user
            let newResponse = completion.choices[0].message.content;
            setCurrentResponse(newResponse);
            setIsLoading(false);

            // Step #5: Render guidelines message
            setInstruction('Agree? Disagree? How should I adjust this? Once you are satisfied, you can move to the next section.')

            // Step #6: Store copy of last response
            setLastBest(feedbackForPrompt);
        }
    }

    return(
        <div className=''>
            <h5 className="font-semibold text-2xl">Behavior</h5>
            <p className="font-light">Behavior refers to the observable and measurable actions or skills that learners will demonstrate as a result of the instructional material. Consider: What is the outcome of the learners participating in the instruction? In other words, what is the audience expected to know or be able to do based on engaging in the instruction? This needs to be written as a verb (this is the “behavior”).</p>
            {
                isLoading === true ? (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full h-48 bg-gray-50 rounded-md my-6 border border-gray-300">
                            <div class="flex items-center justify-center w-full h-full">
                                <div role="status">
                                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-[#238673]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        
                        {/* User responses */}
                        <div class="mb-6">
                            <input type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                        </div>
                    </div>
                ) : (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full max-h-full bg-gray-50 rounded-md my-6 border border-gray-300">
                            <p className="py-4 px-6">{currentResponse}</p>
                        </div>
                        
                        {/* User response */}
                        <div class="mb-2 flex flex-row justify-between">
                            <input value={userFeedback} onInputCapture={onUserFeedbackUpdate} type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                            <button onClick={submitUserResponse} type="button" class="transition duration-200 ease-in-out ml-1 text-white bg-[#238673] hover:bg-[#196455] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none">Submit</button>
                        </div>
                        <p className="text-red-950 text-sm font-semibold">{instruction}</p>
                    </div>
                )
            }
            <div class="flex mt-6">
                <a onClick={() => moveFromStep('back')} class="duration-200 ease-in-out transition hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="duration-200 ease-in-out transition hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )
}

export function LOStepFour({stepChangeCallback, conditionCallback, academicStandard, classDescription, audience, behavior}){

    const [isLoading, setIsLoading] = useState(false);
    const [context, setContext] = useState([]);

    const [currentResponse, setCurrentResponse] = useState('');
    const [userFeedback, setUserFeedback] = useState('');
    const [instruction, setInstruction] = useState('');
    const [lastBest, setLastBest] = useState('');


    // Purpose: Navigation forward/backward
    function moveFromStep(direction){

        if (direction === "back"){
            stepChangeCallback(direction);
        } else if (direction === "forward") {
            // No condition generated
            if (lastBest === ''){
                setInstruction('Please write a Condition before continuing.')
                return;
            } else {
                conditionCallback(lastBest);
                posthog.capture('Set Condition', { property: lastBest })
                stepChangeCallback(direction);
            }
        } else {
            return;
        }
    }

    function onUserFeedbackUpdate(event){
        setUserFeedback(event.target.value);
    }

    // Responding to all user inputs
    async function submitUserResponse(){

        setIsLoading(true);

        // No user input
        if (userFeedback.length < 4){
            setInstruction('To submit feedback, please enter it in the above input. If you are satisfied with my response, you can proceed.');
            setIsLoading(false);
            return;
        }

        if (context.length === 0) { // FIRST input

            // #1: Create initial prompt
            let frameworkIntroduction = "A learning objective is what you want your students to know or be able to do once they have participated in instruction. A learning objective is the foundation of a well-written lesson plan. One of the most direct and effective ways to write a learning objective is to use the ABCD approach. This approach makes sure that you focus on one verb (known as a behavior) that you want your students to be able to accomplish."
            let conditionGuidelines = "Condition, which is the C in ABCD, describes the specific circumstances or resources that learners will use to demonstrate their knowledge or skill. When writing Condition, an educator should consider the following: What are the constraints that will be placed on learners to demonstrate their ability to see the outcome (I.e., the behavior)? The constructions are the specific conditions you place on your learners. It could be, for example, at the end of the lesson/unit/course. It could also be something such as given 30 minutes or using a communication tool of their choosing. The constraint (or constraints) included in the learning objective needs to provide specific guidance/direction to learners and educators on how the behavior is expected to be demonstrated."
            let commonConditionFailures = "When writing the Condition component, common traps to avoid include: being non-specific or non-discrete about how the target audience will demonstrate their knowledge/skill. To avoid this, ensure you can answer the question 'how will I evaluate the behavior my audience engages in?'";
            frameworkIntroduction = `${frameworkIntroduction}\n\n ${conditionGuidelines}\n\n${commonConditionFailures}\n\n`

            let conversationContext = `The following is a conversation with a ${classDescription} educator creating a learning objective ${academicStandard}. The educator will submit their version of Condition and you, an instructional coach, will either either 1. approve it with feedback or 2. disapprove of it with feedback. Be constructive but succinct.\n\nEducator: My Audience is: ${audience}. My behavior is: ${behavior}. For Condition, I want to use: "${userFeedback}".\n\nInstructional Coach:`;
            let fullPrompt = `${frameworkIntroduction}\n\n${conditionGuidelines}\n\n${conversationContext}`
            let firstMessage = {role: 'system', content: fullPrompt};

            // #2: Generate first response
            const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
            const completion = await openai.chat.completions.create({
                model: "gpt-4-0613",
                messages: [firstMessage],
                temperature: 0.5,
                max_tokens: 500
            });

             // Step #3: Add response to context
            let secondMessage = completion.choices[0].message
            const newContext = [...context, firstMessage, secondMessage];
            setContext(newContext);

            // Step #3.5: Analytics
            posthog.capture('Condition Input', { property: userFeedback })
            posthog.capture('Condition Feedback', { property: secondMessage })

            // Step #4: Render response to user
            let newResponse = completion.choices[0].message.content;
            setCurrentResponse(newResponse);
            setIsLoading(false);

            // Step #5: Render guidelines message
            setInstruction('Once you are satisfied with my response, you can move to the next section.')

            // Step #6: Store copy of last response
            setLastBest(userFeedback);
            setUserFeedback('');

            setIsLoading(false);


        } else {    // Follow up inputs

            // Step #1: Housekeeping before rendering new response
            let feedbackForPrompt = userFeedback;
            setUserFeedback('');
            setIsLoading(true);

            // Step #2: Build next prompt
            let nextPrompt = feedbackForPrompt; // customize later on
            let nextMessage = {role: 'user', content: nextPrompt}

            // Step #3: Generate next response
            const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
            const completion = await openai.chat.completions.create({
                 model: "gpt-4-0613",
                messages: [...context, nextMessage],
                temperature: 0.5,
                max_tokens: 500
            });

            // Step #4: Add response to context
            let nextNextMessage = completion.choices[0].message
            const newContext = [...context, nextMessage, nextNextMessage];
            setContext(newContext);

            // Step #3.5: Analytics
            posthog.capture('Condition Input', { property: userFeedback })
            posthog.capture('Condition Feedback', { property: nextNextMessage })

            // Step #5: Render response to user
            let newResponse = completion.choices[0].message.content;
            setCurrentResponse(newResponse);
            setIsLoading(false);

            // Step #5: Render guidelines message
            setInstruction('Agree? Disagree? How should I adjust this? Once you are satisfied, you can move to the next section.')

            // Step #6: Store copy of last response
            setLastBest(feedbackForPrompt);
        }
    }

    return(
        <div className=''>
            <h5 className="font-semibold text-2xl">Condition</h5>
            <p className="font-light">Condition describes the specific circumstances or resources that learners will use to demonstrate their knowledge or skill. Consider: what are the constraints that will be placed on learners to demonstrate their ability to see the outcome? For example: at the end of the lesson/unit/course. It could also be something such as given 30 minutes or using a communication tool of their choosing.</p>
            {
                isLoading === true ? (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full h-48 bg-gray-50 rounded-md my-6 border border-gray-300">
                            <div class="flex items-center justify-center w-full h-full">
                                <div role="status">
                                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-[#238673]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        
                        {/* User responses */}
                        <div class="mb-6">
                            <input type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                        </div>
                    </div>
                ) : (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full max-h-full bg-gray-50 rounded-md my-6 border border-gray-300">
                            <p className="py-4 px-6">{currentResponse}</p>
                        </div>
                        
                        {/* User response */}
                        <div class="mb-2 flex flex-row justify-between">
                            <input value={userFeedback} onInputCapture={onUserFeedbackUpdate} type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                            <button onClick={submitUserResponse} type="button" class="transition duration-200 ease-in-out ml-1 text-white bg-[#238673] hover:bg-[#196455] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none">Submit</button>
                        </div>
                        <p className="text-red-950 text-sm font-semibold">{instruction}</p>
                    </div>
                )
            }
            <div class="flex mt-6">
                <a onClick={() => moveFromStep('back')} class="duration-200 ease-in-out transition hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="duration-200 ease-in-out transition hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )
}

export function LOStepFive({stepChangeCallback, degreeCallback, academicStandard, classDescription, audience, behavior, condition}){

    const [isLoading, setIsLoading] = useState(false);
    const [context, setContext] = useState([]);

    const [currentResponse, setCurrentResponse] = useState('');
    const [userFeedback, setUserFeedback] = useState('');
    const [instruction, setInstruction] = useState('');
    const [lastBest, setLastBest] = useState('');


    // Purpose: Navigation forward/backward
    function moveFromStep(direction){

        if (direction === "back"){
            stepChangeCallback(direction);
        } else if (direction === "forward") {
            // No condition generated
            if (lastBest === ''){
                setInstruction('Please write a Degree before continuing.')
                return;
            } else {
                degreeCallback(lastBest);
                posthog.capture('Set Degree', { property: lastBest })
                stepChangeCallback(direction);
            }
        } else {
            return;
        }
    }

    function onUserFeedbackUpdate(event){
        setUserFeedback(event.target.value);
    }

    // Responding to all user inputs
    async function submitUserResponse(){

        setIsLoading(true);

        // No user input
        if (userFeedback.length < 4){
            setInstruction('To submit feedback, please enter it in the above input. If you are satisfied with my response, you can proceed.');
            setIsLoading(false);
            return;
        }

        if (context.length === 0) { // FIRST input

            // #1: Create initial prompt
            let frameworkIntroduction = "A learning objective is what you want your students to know or be able to do once they have participated in instruction. A learning objective is the foundation of a well-written lesson plan. One of the most direct and effective ways to write a learning objective is to use the ABCD approach. This approach makes sure that you focus on one verb (known as a behavior) that you want your students to be able to accomplish.";
            let degreeGuidelines = "Degree, which is the D in ABCD, is the level of proficiency or competence that the learner is expected to achieve after learning the material. When writing Degree, an educator should consider the following: How will the behavior need to be performed or demonstrated? The degree often describes what is acceptable performance. The degree, in sum, describes what is required for the learner to be considered proficient at the behavior. The specificity of the degree will vary. Often, the degree will not be included if it is easy to measure or if it is obvious (e.g., pass/fail, 100% accuracy is needed)."
            let commonDegreeFailures = "When writing the Degree component, common traps to avoid include: not making the default degree specific to the behavior. Because degree is measuring behavior, the two parts should be consistent and complementary."
            frameworkIntroduction = `${frameworkIntroduction}\n\n ${degreeGuidelines}\n\n${commonDegreeFailures}\n\n`;

            let conversationContext = `The following is a conversation with a ${classDescription} educator creating a learning objective ${academicStandard}. The educator will submit their version of Condition and you, an instructional coach, will either either 1. approve it with feedback or 2. disapprove of it with feedback. Be constructive but succinct.\n\nEducator: My Audience is: ${audience}. My behavior is: ${behavior}. My condition is: ${condition}. For Degree, I want to use: "${userFeedback}}".\n\nInstructional Coach:`;
            let fullPrompt = `${frameworkIntroduction}\n\n${degreeGuidelines}\n\n${conversationContext}`
            let firstMessage = {role: 'system', content: fullPrompt};

            // #2: Generate first response
            const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
            const completion = await openai.chat.completions.create({
                model: "gpt-4-0613",
                messages: [firstMessage],
                temperature: 0.5,
                max_tokens: 500
            });

             // Step #3: Add response to context
            let secondMessage = completion.choices[0].message
            const newContext = [...context, firstMessage, secondMessage];
            setContext(newContext);

            // Step #3.5: Analytics
            posthog.capture('Degree Input', { property: userFeedback })
            posthog.capture('Degree Feedback', { property: secondMessage })

            // Step #4: Render response to user
            let newResponse = completion.choices[0].message.content;
            setCurrentResponse(newResponse);
            setIsLoading(false);

            // Step #5: Render guidelines message
            setInstruction('Once you are satisfied with my response, you can move to the next section.')

            // Step #6: Store copy of last response
            setLastBest(userFeedback);
            setUserFeedback('');

            setIsLoading(false);


        } else {    // Follow up inputs

            // Step #1: Housekeeping before rendering new response
            let feedbackForPrompt = userFeedback;
            setUserFeedback('');
            setIsLoading(true);

            // Step #2: Build next prompt
            let nextPrompt = feedbackForPrompt; // customize later on
            let nextMessage = {role: 'user', content: nextPrompt}

            // Step #3: Generate next response
            const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
            const completion = await openai.chat.completions.create({
                 model: "gpt-4-0613",
                messages: [...context, nextMessage],
                temperature: 0.5,
                max_tokens: 500
            });

            // Step #4: Add response to context
            let nextNextMessage = completion.choices[0].message
            const newContext = [...context, nextMessage, nextNextMessage];
            setContext(newContext);

            // Step #3.5: Analytics
            posthog.capture('Degree Input', { property: userFeedback })
            posthog.capture('Degree Feedback', { property: nextNextMessage })

            // Step #5: Render response to user
            let newResponse = completion.choices[0].message.content;
            setCurrentResponse(newResponse);
            setIsLoading(false);

            // Step #5: Render guidelines message
            setInstruction('Agree? Disagree? Once you are satisfied, you can move to the next section.')

            // Step #6: Store copy of last response
            setLastBest(feedbackForPrompt);
        }
    }

    return(
        <div className=''>
            <h5 className="font-semibold text-2xl">Degree</h5>
            <p className="font-light">Degree is the level of proficiency or competence that the learner is expected to achieve after learning the material. Consider: how will the behavior need to be performed or demonstrated? The degree often describes what is acceptable performance. The degree, in sum, describes what is required for the learner to be considered proficient at the behavior.</p>
            {
                isLoading === true ? (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full h-48 bg-gray-50 rounded-md my-6 border border-gray-300">
                            <div class="flex items-center justify-center w-full h-full">
                                <div role="status">
                                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-[#238673]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        
                        {/* User responses */}
                        <div class="mb-6">
                            <input type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                        </div>
                    </div>
                ) : (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full max-h-full bg-gray-50 rounded-md my-6 border border-gray-300">
                            <p className="py-4 px-6 ">{currentResponse}</p>
                        </div>
                        
                        {/* User response */}
                        <div class="mb-2 flex flex-row justify-between">
                            <input value={userFeedback} onInputCapture={onUserFeedbackUpdate} type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                            <button onClick={submitUserResponse} type="button" class="transition duration-200 ease-in-out ml-1 text-white bg-[#238673] hover:bg-[#196455] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none">Submit</button>
                        </div>
                        <p className="text-red-950 text-sm font-semibold">{instruction}</p>
                    </div>
                )
            }
            <div class="flex mt-6">
                <a onClick={() => moveFromStep('back')} class="duration-200 ease-in-out transition hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="duration-200 ease-in-out transition hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )
}

export function LOStepSix({stepChangeCallback, learningObjectiveCallback, academicStandard, classDescription, audience, behavior, condition, degree}){

    const [isLoading, setIsLoading] = useState(false);
    const [currentResponse, setCurrentResponse] = useState('');
    const [instruction, setInstruction] = useState('');
    const [lastBest, setLastBest] = useState('');

    // Responding to all user inputs
    async function submitUserResponse(){

        setIsLoading(true);

        // #1: Create initial prompt
        let frameworkIntroduction = `A learning objective is a statement that describes what learners will be expected to know or be able to do by the end of a lesson/unit of instruction/project/course/semester/school year. A well-written learning objective describes the intended learning outcome. A main purpose of learning objectives is to help educators in the design and development of effective and efficient instructional experiences for their learners. A well-written learning objective provides guidance for an educator (and their learners) about what is expected of the learners. Who, what, when, and at what level are described in a well-written learning objective. The ABCD framework is a way of structuring learning objectives for instructional material.`
        let descriptionPrompt = `I am a ${classDescription} educator ${academicStandard} I've defined the following parts:\n`
        let ABCDPrompt = `Audience: ${audience}\n\nBehavior: ${behavior}\n\nCondition: ${condition}\n\nDegree: ${degree}`;
        let finalPrompt = frameworkIntroduction + "\n\n" + descriptionPrompt + ABCDPrompt + "\n\nUse this information and your knowledge of ABCD framework, Bloom's Taxonomy, and learning objectives to write a one-sentence learning objective for my students:"
        let firstMessage = {role: 'system', content: finalPrompt};

        // #2: Generate first response
        const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
        const completion = await openai.chat.completions.create({
            model: "gpt-4-0613",
            messages: [firstMessage],
            temperature: 0.5,
            max_tokens: 500
        });

        // Step #4: Render response to user
        let newResponse = completion.choices[0].message.content;

        // Step #3.5: Analytics
        posthog.capture('LO Generated', { property: newResponse })

        setCurrentResponse(newResponse);
        setLastBest(newResponse);
        learningObjectiveCallback(newResponse);
        setIsLoading(false);

        // Step #5: Render guidelines message
        setInstruction('Once you are satisfied with the generated objective, you can move to the next section.')
    }


    // Purpose: Navigation forward/backward
    function moveFromStep(direction){

        if (direction === "back"){
            stepChangeCallback(direction);
        } else if (direction === "forward") {
            // No learning objective generated
            if (lastBest === ''){
                setInstruction('Please generate a Learning Objective before continuing.')
                return;
            } else {
                learningObjectiveCallback(lastBest);
                posthog.capture('Set Learning Objective', { property: lastBest })
                stepChangeCallback(direction);
            }
        } else {
            return;
        }
    }

    return(
        <div className=''>
            <h5 className="font-semibold text-2xl">Final Learning Objective</h5>
            <p className="font-light">Generate a final learning objective.</p>
            {
                isLoading === true ? (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full h-48 bg-gray-50 rounded-md my-6 border border-gray-300">
                            <div class="flex items-center justify-center w-full h-full">
                                <div role="status">
                                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-[#238673]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                ) : (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full max-h-full bg-gray-50 rounded-md my-6 border border-gray-300">
                            <p className="py-4 px-6">{currentResponse}</p>
                        </div>

                        {/* User generated */}
                        <button onClick={submitUserResponse} type="button" class="transition duration-200 ease-in-out ml-1 text-white bg-[#238673] hover:bg-[#196455] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">Generate</button>
                        <p className="text-red-950 text-sm font-semibold">{instruction}</p>
                    </div>
                )
            }
            <div class="flex mt-6">
                <a onClick={() => moveFromStep('back')} class="duration-200 ease-in-out transition transition duration-200 ease-in-out hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="duration-200 ease-in-out transition transition duration-200 ease-in-out hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )
}

export function LOStepSeven({stepChangeCallback, audience, behavior, condition, degree, finalLearningObjective}){
    

    const [learningObjectiveIsFinished, setLearningObjectiveIsFinished] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    
    function moveFromStep(direction){

        if (direction === "back"){
            stepChangeCallback(direction);
        } else if (direction === "forward") {
            stepChangeCallback(direction);
        } else {
            return;
        }
    }

    return(
        <div className=''>
            {
                learningObjectiveIsFinished ? (
                    <div></div>
                ) : (
                    <div>
                        <Confetti
                            width={windowWidth}
                            height={windowHeight}
                            numberOfPieces={20}
                            tweenDuration={1}
                        />
                    </div>
                )
            }
            {/* <h5 className="font-semibold text-2xl">Summary</h5>
            <p className="font-light">Review your work on the learning objective.</p> */}
            
            <div className="mt-10">
                {/* <h1 className="font-medium text-lg pb-1">Audience: <span className="pb-3 font-light text-sm">{audience}</span></h1>
                <h1 className="font-medium text-lg pb-1">Behavior: <span className="pb-3 font-light text-sm">{behavior}</span></h1>
                <h1 className="font-medium text-lg pb-1">Condition: <span className="pb-3 font-light text-sm">{condition}</span></h1>
                <h1 className="font-medium text-lg pb-1">Degree: <span className="pb-3 font-light text-sm">{degree}</span></h1> */}
                <h1 className="font-light text-xl text-center ml-10 mr-10">{finalLearningObjective} </h1>
            </div>
            
            <div className="mt-14 flex flex-col justify-center items-center">
                <h1 className="text-3xl mb-4 font-semibold">Sign up to save your work</h1>
                <SignInWithMicrosoft audience={audience} behavior={behavior} condition={condition} degree={degree} finalLearningObjective={finalLearningObjective}/>
                <SignInWithGoogle audience={audience} behavior={behavior} condition={condition} degree={degree} finalLearningObjective={finalLearningObjective}/>
            </div>
            
    
        </div>
    )
}