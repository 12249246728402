import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { auth } from './Firebase';

async function AuthDetails(){

  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return authUser;
}

export default AuthDetails;

export function checkAuthStatus(){
    const user = auth.currentUser;
    if (user == null){
        return null
    } else {
        return user
    }
}
