import React, { useState } from "react";

import LOCoordinator from "./LOCoordinator";

function openClassroomGPT(){
    window.open('https://classroomgpt.app', '_blank');
}

function ExperimentHome({}){
    return(
        <div className="bg-[#F6F6F6]">
            <h1 className="pt-10 text-center font-semibold text-3xl">K-12 Learning Objective Builder</h1>
            <h1 className="pt-2 pb-6 text-center font-light text-xl">Powered by <span onClick={openClassroomGPT} className="duration-200 ease-in-out transition text-[#2CA58D] hover:cursor-pointer hover:text-[#238673]">ClassroomGPT</span></h1>
            <div className="ml-10 mr-10 mb-10 border border-gray-700 rounded-md p-8 bg-[#f0f0f0]">
                <LOCoordinator />
            </div>
        </div>
    )
}

export default ExperimentHome;