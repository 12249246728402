import React, { useState } from 'react';

// Authentication components
import { auth, provider } from './Firebase';
import { signInWithPopup } from 'firebase/auth';
import { checkAuthStatus } from '../Authentication/AuthDetails';
import { experimentOneCreateEntireLesson } from './Firebase';

// Styling components
import './SignIn.css'

function SignInWithGoogle({audience, behavior, condition, degree, finalLearningObjective}){

    const [error, setError] = useState('')
    const [signedIn, setSignedIn] = useState(false);

    function signInWithGoogle(e){
        setError('');
        e.preventDefault();
        signInWithPopup(auth, provider)
        .then((result) => {
            const user = result.user;
            setSignedIn(true);
            
        }).catch((error) => {
            const errorMessage = error.message;
            if (errorMessage === "Firebase: Error (auth/popup-closed-by-user)."){
                setError('Pop up closed. Please try again.')
            } else {
                setError('Unknown error. Please try again')
            }
        });
    }

    if (signedIn){

        const userIsIn = checkAuthStatus();

        // Add new lesson to user's account
        experimentOneCreateEntireLesson(userIsIn.uid, audience, behavior, condition, degree, finalLearningObjective)
        window.location.href = "https://classroomgpt.app";
    }

    return (
        <div className='max-w-fit'>
            <div className='hover:cursor-pointer border border-solid border-gray rounded-md bg-white px-9 py-2 flex flex-row justify-center items-center hover:bg-[#f9f9f9] ease-in-out duration-200' type="button" onClick={signInWithGoogle}>
                <img className='w-5 h-5 mr-3' src="https://i.imgur.com/V1zjVz5.png" alt="Google Logo"></img>
                <h5 className='font-normal text-md opacity-80'>Continue with Google</h5>
            </div>
            <div className='text-center text-red-900 mt-1 mb-2 font-light text-sm'>
                {error}
            </div>
        </div>
    )
}

export default SignInWithGoogle;