import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from 'react';

// Pages
import ExperimentHome from "./Pages/Experiment/ExperimentHome";

// Analytics
import posthog from 'posthog-js'

function App() {

  posthog.init('phc_mw8xb5Oejz31Lwy3eap0xYmnVm9DRQTMvG1Xi3YiVVZ', { api_host: 'https://app.posthog.com' })

  return (
    <Router>
      <Routes>
        <Route path="/" element={<ExperimentHome />}/>
        <Route path="*" element={<ExperimentHome />} />
      </Routes>
    </Router>
  );
}

export default App;
